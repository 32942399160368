import React from 'react'

class SleepServer extends React.Component{
    render(){
        return (
            <>
                <img src="/img/sleep.gif" className="mx-0 rounded-md w-screen 2xl:w-screen" alt="NotFound"></img>
            </>
        )
    }
}

export default SleepServer
import React from 'react'

class NotFound extends React.Component{
    render(){
        return(
            <>
                <img src="/img/notfound.jpg" class="rounded-3xl" alt="NotFound"></img>
            </>
        )
    }
}

export default NotFound